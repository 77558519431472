button{
    cursor: pointer;
}
@each $colorName, $colorValue in $colors{
    .button--#{$colorName}{
		@include transition(all);
		padding: rem-calc(13px 30px);
        border:2px solid #{$colorValue};
        border-radius: rem-calc(5);
        font-size: rem-calc(14);
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: rem-calc(1);
		display: inline-block;
		@if $colorName == 'white' {
            color: $primary-color;
		}@else {
			background:$colorValue;
			color:$white;
		}
		&:hover,&:focus{
			background:#{$colorValue};
			@if $colorName == 'white' {
				color: $primary-color;
			}@else if $colorName == 'primary' {
                color: $white;
                background:$secondary-color;
                border-color:$secondary-color;
            }@else if $colorName == 'secondary' {
                color: $white;
                background:$primary-color;
                border-color:$primary-color;
            }@else {
				color: $white;
			}
		}
	}
    .button--#{$colorName}-outline{
        @include transition(all);
        padding: rem-calc(13px 30px);
        border:2px solid #{$colorValue};
        border-radius: rem-calc(5);
        font-size: rem-calc(14);
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: rem-calc(1);
        display: inline-block;
        color: $colorValue;
        &:hover,&:focus{
            background:#{$colorValue};
            @if $colorName == 'white' {
                color: $header-color;
            }@else {
                color: $white;
            }
        }
    }
    button.button--#{$colorName}-outline{
        padding: rem-calc(15px 30px);
    }
}

