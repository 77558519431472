body.showBlockNames [data-block]:before {
	content: attr(data-block);
	position: absolute;
	left: 0;
	background-color: green;
	color: white;
	padding: 4px;
	z-index: 99999;
}
.absolute {
	position: absolute;
}
.relative {
	position: relative;
}
.dp-block {
	display: block;
}
.inline-block {
	display: inline-block;
}
.uppercase {
	text-transform: uppercase;
}

.valign-middle {
	vertical-align: middle;
}
.strong, .bold {
	font-weight: 700;
}
img[data-src] {
    @include transition(all);
    transition-duration: 300ms;
    &:not(.image-loaded) {
        filter: blur(2px);
        width: 100%;
    }
}
.image-loaded{
	+img{
		@include transition(all);
		transition-duration: 150ms;
		opacity: 0;
	}
}

.img--thumb{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	filter: blur(10px);
}

@mixin interchangeImage{
    overflow: hidden;
    img{
        @include vertical-center;
        position: absolute;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        pointer-events: none;
        filter: blur(10px);
    }
    &.child-image-is-loaded{
        img{
            transition: all ease-out 150ms;
            opacity: 0;
        }
    }
}

.child-image{
	filter: blur(10px);
}

.child-image-is-loaded{
	.child-image{
		transition: all ease-out 150ms;
		opacity: 0;
	}
}
.background{
	overflow: hidden;
	.child-image{
		@include vertical-center;
		//opacity: 1 !important;
		width: 100%;
	}
}
