.event-item{
    &__date{
        width: 52px;
        text-align: center;
    }
    &__month{
        font-weight: 700;
        text-transform: uppercase;
        color:$primary-color;
        font-size: rem-calc(24);
    }
    &__day{
        font-weight: 700;
        text-transform: uppercase;
        color:$secondary-color;
        font-size: rem-calc(30);
    }
}
