footer{
	background:$header-color;
	position: relative;
	z-index: 1;
	padding:rem-calc(73 0);
	width: 100%;
	@include text-spects{
		color:$white;
	}
	a{
		color:$white;
	}
	ul{
		margin:0;
		padding:0;
		list-style-type: none;
		li{
			margin:rem-calc(0 0 2px);
			padding:0;
			color:$white;
		}
	}
    @include breakpoint(large up){
        .cell{
            padding-right: rem-calc(45);
        }
    }
    @include breakpoint(small only){
        .cell{
            +.cell{
                margin-top: 30px;
            }
        }
    }
}
