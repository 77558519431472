.callout{
    padding: rem-calc(30 10 40);
    @include breakpoint(medium up) {
        padding: rem-calc(60 80);
    }
    @include breakpoint(large up){
        padding:rem-calc(110 120);
    }
    @include text-spects{
        color:$white;
    }
    h1,h2,h3,h4{
        margin-bottom: rem-calc(30);
    }
}
