.social-list{
    &__footer{
        margin:0;
        padding:0;
        i{
            @include transition(background);
            border-radius: 5px;
            border:2px solid $white;
            width: 43px;
            height: 43px;
            text-align: center;
            top: 0;
            left: 0;
            position: absolute;
            &::before{
                @include transition(color);
                @include vertical-center;
                left: 0;
                width: 100%;
            }
        }
        a{
            display: inline-block;
            font-weight: 700;
            position: relative;
            padding:rem-calc(10px 0 10px 55px);
            &:hover{
                color:$white;
                i{
                    background:$white;
                    &::before{
                        color:$header-color;
                    }
                }
            }
        }
        li{
            margin:0;
            +li{
                margin-top: 10px;
            }
        }
    }
}
