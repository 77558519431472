// @include responsive-font(5vw, 35px, 150px, 50px);

.hero-header{
    @include responsive-font(5vw, 30px, 60px, 50px);
    font-weight: 800;
    line-height: 1.15;
    margin-bottom: 0;
}

h1 {
    @include responsive-font(5vw, 30px, 70px, 70px);
}

h2{
    @include responsive-font(5vw, 30px, 40px, 40px);
}
h3{
    @include responsive-font(5vw, 20px, 24px, 24px);
}
h4{
    @include responsive-font(5vw, 18px, 18px, 18px);
}
h5{
    @include responsive-font(5vw, 16px, 18px, 18px);
}
h6{
    font-size: 16px;
}
