.submenu{
    margin:0;
    padding:0;
    list-style: none;
    li{
        +li{
            margin-top: 10px;
        }
        &.is-active{
            a{
                background:$secondary-color;
                color:$white;
            }
        }
        a{
            display: block;
        }
    }
}
