.user-list{
    >.cell{
        @include breakpoint(small only){
            &:nth-child(n+2){
                margin-top: 30px;
            }
        }
        @include breakpoint(medium up){
            &:nth-child(n+3){
                margin-top: 30px;
            }
        }
    }
    &__image{
        @include aspect-ratio(1,1);
        @include interchangeImage;
        background-size: cover;
        background-position: center;
        border-radius: 10px 10px 0 0;
    }
    &__number{
        color:$secondary-color;
        font-weight: 800;
        i{
            margin-right: rem-calc(7px);
        }
    }
}
