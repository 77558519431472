.card{
    border:0;
    border-radius: rem-calc(10);
    box-shadow: 5px 10px 40px rgba(#000000,.1);
    background: $white;
    &__image{
        @include aspect-ratio(16,9);
        @include interchangeImage;
        background-size: cover;
        background-position: center;
        border-radius: 10px 10px 0 0;
    }
    &__section{

        padding:rem-calc(30px);
        >*:last-child{
            margin-bottom: 0;
        }
    }
    &-list{
        >.cell{
            @include breakpoint(small only){
                &:nth-child(n+2){
                    margin-top: 30px;
                }
            }
            @include breakpoint(medium only){
                &:nth-child(n+3){
                    margin-top: 30px;
                }
            }
            @include breakpoint(large up){
                a:nth-child(n+4){
                    margin-top: 30px;
                }
            }
        }
    }
}
