.main-header{
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    position: relative;
    padding: rem-calc(260 0 100);
    .child-image{
        position: absolute;
        left: 0;
        width: 100%;
        @include vertical-center;
        pointer-events: none;
    }
    h1{
        color:$white;
        text-shadow: 5px 5px 20px #000000;
        +p{
           margin-top: rem-calc(40);
        }
    }
    p{
        margin-bottom: 0;
    }
}
