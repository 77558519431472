.main-menu{
    $p: &;
    &__container{
        @include transition(all);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 50;
        &.is-stuck{
            background: $white;
            height: rem-calc(100px);
            .main-menu{
                li{
                    @include transition(all);
                    +li{
                        margin-left: 0;
                    }
                    a{
                        display: inline-block;
                        padding:rem-calc(34px 25);
                        color:$header-color;
                    }
                    &.is-active,&:hover{
                        border-color: $primary-color;
                        a{
                            color:$secondary-color;
                        }
                    }
                }
                &__logo{
                    padding:rem-calc(25px 0);
                    display: inline-block;
                    width: rem-calc(106);
                }
            }
        }
        @include breakpoint(medium down){
            .cell{
                align-self: center;
            }
        }
        &:not(.is-stuck){
            @include breakpoint(medium down){
                background: $white;
                height: rem-calc(100px);
                .main-menu{
                    li{
                        @include transition(all);
                        +li{
                            margin-left: 0;
                        }
                        a{
                            display: inline-block;
                            padding:rem-calc(34px 25);
                            color:$header-color;
                        }
                        &.is-active,&:hover{
                            border-color: $primary-color;
                            a{
                                color:$secondary-color;
                            }
                        }
                    }
                    &__logo{
                        padding:rem-calc(25px 0);
                        display: inline-block;
                        width: rem-calc(106);
                    }
                }
            }
            @include breakpoint(large up){
                .main-menu{
                    display: inline-block;
                    margin:0;
                    list-style-type: none;
                    li{
                        a{
                            display: inline-block;
                            padding:20px 0;
                            @include breakpoint(medium up) {
                                padding:rem-calc(80px 25 0) !important;
                            }
                            color:$white;
                        }
                        &.is-active,&:hover{
                            a{
                                color:$secondary-color;
                            }
                        }
                    }
                    &__logo{
                        @include transition(all);
                        background:$white;
                        padding:rem-calc(27px 31px);
                        height: rem-calc(185);
                        border-radius: rem-calc(0 0 10 10);
                        display: inline-block;
                        position:absolute;
                        top: 0;
                        left: 0;
                        width: rem-calc(185);
                        &-container{
                            position: relative;
                        }
                    }
                }
                &.is-sticky{
                    background:$white;
                    li{
                        &.is-active,&:hover {
                            border-color: $primary-color;
                        }
                        a{
                            display: inline-block;
                            padding:rem-calc(34px 25) !important;
                            color:$header-color;
                        }
                    }
                    .main-menu{
                        &__logo{
                            background: transparent;
                            width: rem-calc(70);
                            padding:rem-calc(12px 0px);
                            height: rem-calc(100);
                        }
                    }
                }
            }
        }
    }
    li{
        @include transition(all);
        border-top:4px solid transparent;
        margin:0;
        padding:0;
        display: inline-block;
        a{
            letter-spacing: rem-calc(1);
            font-weight: 800;
            text-transform: uppercase;
            font-size: rem-calc(14);
        }
    }
}
