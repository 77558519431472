form.sending {
	.form-group {
		opacity: 0.5;
	}
}
.form__container {
	position: relative;
	z-index: 0;
	.form__loader {
		z-index: -1;
		opacity: 0;
		background-color: rgba($white, 0.7);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@include transition(all);
		svg {
			width: 50px;
			height: 50px;
			@include horizontal-center();
			top: 30%;
		}
	}
	&.sending {
		.form__loader {
			z-index: 1;
			opacity: 1;
		}
	}
}

.form-group{
    @extend .card;
    position: relative;
    margin-bottom: rem-calc(30px);
    overflow: hidden;
    label{
        @include transition(all);
        position: absolute;
        top: rem-calc(18);
        left: 15px;
        font-size: rem-calc(16);
        color:$body-font-color;
    }
    [type='text'],[type='email'],[type='tel'],textarea{
        background:transparent;
        margin-bottom: 0;
        border:none;
        font-size: rem-calc(16);
        color:$body-font-color;
        box-shadow: none;
        z-index: 1;
        padding-top: 20px;
        padding-left: 15px;
        position: relative;
        height: rem-calc(61);
        border-bottom:2px solid transparent;;
        &.is-invalid-input + label,
        &.valid + label,
        &:focus + label{
            top: rem-calc(3);
            font-size: rem-calc(12);
            border-color: $primary-color !important;
        }
        &:focus{
            border-bottom: 2px solid $primary-color !important;
        }
        &.is-invalid-input{
            background: transparent;
            border-color:$alert-color;
            + label{
                color:$alert-color;
            }
        }
        &.valid{
            color: $primary-color;
            border-color: $success-color;
        }
        &[required]{
            + label{
                &::after{
                    left: 2px;
                    position: relative;
                    content: '*';
                    color:$alert-color;
                }
            }
        }
    }
    textarea{
        min-height: 150px;
        resize: none;
    }
    &.passed{
        [type='text'],[type='email'],[type='tel'],textarea{
            + label {
                top: rem-calc(3);
                font-size: rem-calc(12);
                color: $success-color;
            }
        }
    }
    .form-error {
        margin-top: 7px;
        margin-bottom: 1.5rem;
    }
}
