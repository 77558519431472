.ambassador-list{
    >.cell{
        +.cell{
            margin-top: 30px;
        }
    }
    &__image{
        @include aspect-ratio(6,7);
        @include interchangeImage;
        background-size: cover;
        background-position: center;
        border-radius: 10px 0 0 10px;
    }
    &__number{
        color:$secondary-color;
        font-weight: 800;
        i{
            margin-right: rem-calc(7px);
        }
    }
}
