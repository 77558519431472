$primary-color: #D81132;
$secondary-color: #4A9134;
$white: #FFFFFF;
$black: #000000;
$body-font-color:#838383;
$colors: (
    'primary' : $primary-color,
    'secondary' : $secondary-color,
    'white' : $white,
    'black' : $black,
    'body' : $body-font-color
);
