.logo-list{
    &__logo{
        display: none;
    }
    a,span{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-height: 100px;
        }
    }
    @include breakpoint(small only){
        &__logo:nth-child(n+3){
            margin-top: 30px;
        }
    }
    @include breakpoint(medium only){
        &__logo:nth-child(n+4){
            margin-top: 30px;
        }
    }
    @include breakpoint(large up){
        &__logo:nth-child(n+7){
            margin-top: 30px;
        }
    }
}
