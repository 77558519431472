.responsive-menu{
    padding-top:20px;
    img{
        width: rem-calc(130);
    }
    ul{
        border-top:1px solid darken($light-gray,5%);
        margin:rem-calc(20 0 0);
        padding:rem-calc(30 0 0 0);
        li{
            margin-bottom: 20px;
            text-align: center;
            &.active,&:hover{
                a{
                    color:$secondary-color;
                }
            }
        }
        a{
            color:$header-color;
            text-transform: uppercase;
            font-size: rem-calc(16);
            font-weight: 800;
        }
    }
}
