//Sticky footer
.page__container{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.page__content{
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}