.post-detail{
    padding-top: rem-calc(100);
    &__image{
        @include aspect-ratio(16,6);
        @include interchangeImage;
        background-size: cover;
        background-position: center;
        border-radius: 10px 10px 0 0;
    }
    &__date{
        color:$primary-color;
        font-weight: 800;
    }
}
